@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-slide .image-gallery-image {
    max-width: 800px;
}

.image-gallery-slide img {
    background-color: #262626;
    height: 450px;
}
